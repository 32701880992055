<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <div style="background-color: #ebf0ff">
      <v-layout wrap justify-center pt-5 pb-10>
        <v-flex xs12 sm12 md10>
          <v-tabs
            v-model="tabValue"
            color="#7CB9E8"
            grow
            dark
            center-active
            centered
            :show-arrows="true"
            active-class="activ"
            class="nonactiv"
            background-color="#ebf0ff"
            style="font-family: robotoRegular"
          >
            <v-tab style="color: #000" dark href="#tab-1">Transfer Details</v-tab>
            <!-- <v-tab style="color: #000" dark href="#tab-2">MANAGEMENT</v-tab> -->
            <v-tab style="color: #000" dark href="#tab-3"
              >Supporting files</v-tab
            >
            <v-tab-item value="tab-1">
              <v-card flat style="background-color: #ebf0ff">
                <Page1 @stepper="winStepper" :asset="asset" />
              </v-card>
            </v-tab-item>
            <v-tab-item value="tab-2">
              <v-card
                flat
                style="background-color: #ebf0ff"
                v-if="subLevel.includes(1)"
              >
                <Page2
                  value="tab-2"
                  @stepper="winStepper"
                  :asset="asset"
                  :manage="manage"
                  :assetIdNew="assetIdNew"
                />
              </v-card>
              <v-card
                flat
                style="background-color: #ebf0ff"
                height="580px"
                v-else
              >
                <v-layout wrap justify-center py-16>
                  <v-flex xs12 pt-10>
                    <span style="font-family: sedanRegular; font-size: 20px"
                      >Please complete Asset Details to proceed with
                      Management</span
                    >
                  </v-flex>
                </v-layout>
              </v-card>
            </v-tab-item>

            <v-tab-item value="tab-3">
              <v-card
                flat
                style="background-color: #ebf0ff"
              >
              <!-- v-if="subLevel.includes(1) && subLevel.includes(2)" -->

                <Page3
                  value="tab-3"
                  :asset="asset"
                  :manage="manage"
                  :subLevel="subLevel"
                  :assetIdNew="assetIdNew"
                  :users="users"
                />
              </v-card>
              <!-- <v-card
                flat
                style="background-color: #ebf0ff"
                height="580px"
                v-else
              >
                <v-layout wrap justify-center py-16>
                  <v-flex xs12 pt-10>
                    <span style="font-family: sedanRegular; font-size: 20px"
                      >Please complete previous 2 pages</span
                    >
                  </v-flex>
                </v-layout>
              </v-card> -->
            </v-tab-item>
          </v-tabs>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>
  
  <script>
import Page1 from "./page1";
// import Page2 from "./page2";
import Page3 from "./page3";
export default {
  components: {
    Page1,
    // Page2,
    Page3,
  },
  data: () => ({
    appLoading: false,
    ServerError: false,
    tabValue: "tab-1",
    asset: {},
    manage: {},
    assetId: "",
    users: "",
    assetIdNew: "",
    check: [],
    subLevel: [],
    items: ["Transfer Details", "MANAGEMENT", "Supporting files"],
  }),
  methods: {
    winStepper(item) {
      this.flag = false;
      window.scrollTo(0, 0);
      if (item.ref == "page1") {
        if (item.assetDetails) {
          this.asset = item.assetDetails;
        }
      }
      if (item.ref == "page1Tab") {
        if (item.level || item.check || item.subLevel || item.assetDetails) {
          this.tabValue = item.level;
          this.check = item.check;
          this.subLevel.push(item.subLevel);
          this.asset = item.assetDetails;
        }
      }
      if (item.ref == "page1SubTab") {
        if (item.subLevel) {
          this.subLevel = item.subLevel;
        }
      }
      if (item.ref == "page2Tab") {
        if (item.level || item.subLevel || item.management) {
          this.tabValue = item.level;
          this.subLevel.push(item.subLevel);
          this.manage = item.management;
        }
      }
      if (item.ref == "page2") {
        if (item.management) {
          this.manage = item.management;
        }
      }
      if (item.ref == "managementPage") {
        if (item.management || item.assetIdNew || item.users) {
          this.manage = item.management;
          this.assetIdNew = item.assetIdNew;
          this.users = item.users;
        }
      }
      if (item.ref == "assetGet") {
        if (item.assetIdNew) {
          this.assetIdNew = item.assetIdNew;
        }
      }
      if (item.ref == "assetGetPage2") {
        if (item.assetIdNew) {
          this.assetIdNew = item.assetIdNew;
        }
      }
      if (item.ref == "assetPage1") {
        if (item.assetDetails) {
          this.asset = item.assetDetails;
        }
      }
    },
  },
};
</script>
  <style scoped>
.activ {
  color: black !important;
  font-family: robotoRegular !important;
}
.nonactiv {
  color: #acacac !important;
  font-family: robotoRegular !important;
}
</style>
  